const e = "app", f = {
  "dark-blue": "#00144e",
  "mid-blue": "#0237cc",
  "mq-blue": "#0066ff",
  "light-blue": "#3d8bff",
  "mq-orange": "#ff471c",
  "digital-orange": "#fe820d",
  "digital-green": "#1dfeb0",
  salmon: "#fc5959",
  black: "#000000",
  "mq-black": "#282725",
  "grey-5": "#6b6b6b",
  "grey-4": "#999999",
  "grey-3": "#c8c8c8",
  "grey-2": "#efefef",
  "grey-1": "#f7f7f7",
  white: "#ffffff",
  danger: "#e0263a",
  warning: "#ffc500",
  success: "#2fb17f"
}, r = {
  thin: 300,
  light: 300,
  regular: 400,
  bold: 700
}, a = {
  mobile: 0,
  tablet: 577,
  desktop: 992
};
export {
  e as appColorVarPrefix,
  a as breakpointMap,
  r as mqFontWeights,
  f as mqThemeColors
};

import { jsx as o } from "react/jsx-runtime";
import { useMemo as a } from "react";
import { Alert as l, CheckmarkCircle as i, AlertCircle as t } from "@machineq/icons";
/* empty css                         */
import { Alert as u } from "../Alert/Alert.js";
import { Toaster as p } from "../Toast/Toaster.js";
import { useToast as f } from "../Toast/toast.useToast.js";
import { exhaustiveMatchGuard as A } from "../utils/exhaustiveMatchGuard.js";
import { Icon as C } from "../../src/1_atoms/icon/Icon.js";
const e = "toast-alert", d = "alertToastStyles_andx2ln", T = ({
  closeToast: s,
  dxVariant: r,
  DXAdornment: n,
  ...c
}) => {
  const m = a(() => {
    switch (r) {
      case "error":
        return {
          Icon: t,
          mqColor: "danger"
        };
      case "info":
        return {
          Icon: t,
          mqColor: "grey-4"
        };
      case "success":
        return {
          Icon: i,
          mqColor: "success"
        };
      case "warning":
        return {
          Icon: l,
          mqColor: "warning"
        };
      default:
        return A();
    }
  }, [r]);
  return /* @__PURE__ */ o(u, {
    ...c,
    dxVariant: r,
    DXAdornment: n ?? /* @__PURE__ */ o(C, {
      ...m,
      mqAccessibility: "decorative"
    }),
    className: d,
    dxOnClose: s
  });
}, M = () => /* @__PURE__ */ o(p, {
  id: e,
  ToastComponent: T
}), S = () => f({
  id: e
});
export {
  T as ToastAlert,
  M as ToasterAlert,
  S as useToastAlert
};

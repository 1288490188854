import { useCallback as h, useMemo as r } from "react";
import { setToastOptions as s, toastContainerId as f } from "./toast.utils.js";
const y = (e) => {
  const n = h(
    (d) => {
      if (typeof window > "u") return;
      const c = (e == null ? void 0 : e.autoClose) || 4, u = (e == null ? void 0 : e.id) ?? f, t = document.querySelector(`#${u}`);
      if (!t && (e != null && e.id)) {
        console.error(
          `Cannot find the Toaster ID of "${e.id}" in the DOM. Please ensure that you have added the <Toaster /> component and supplied the id="${e.id}"' prop to it anywhere in the React tree.`
        );
        return;
      }
      if (!t) {
        console.error(
          "Cannot find the Toaster ID in the DOM. Please ensure that you add the <Toaster /> component anywhere in the React tree."
        );
        return;
      }
      const o = document.createElement("article");
      function i() {
        console.log("Removing toast node"), o.remove();
      }
      const l = s({
        ...d,
        closeToast: i
      });
      o.setAttribute("id", l), t.appendChild(o), setTimeout(() => {
        i();
      }, c * 1e3);
    },
    [e == null ? void 0 : e.id, e == null ? void 0 : e.autoClose]
  );
  return r(
    () => ({
      create: n
    }),
    [n]
  );
};
export {
  y as useToast
};

import { jsxs as r, jsx as e } from "react/jsx-runtime";
import { forwardRef as p, cloneElement as d } from "react";
import { Close as f } from "@machineq/icons";
/* empty css                    */
import { classes as a } from "../utils/classes.js";
import { Icon as N } from "../../src/1_atoms/icon/Icon.js";
const u = "alertStyles_apo2x3t", b = "closeButtonStyles_c13n6bad", C = p(function({
  className: m,
  DXAdornment: l,
  dxOnClose: s,
  dxVariant: t,
  dxHeader: c,
  dxPrimary: o,
  dxSecondary: i,
  ...n
}, h) {
  return /* @__PURE__ */ r("article", {
    ...n,
    className: a(u, m, t),
    ref: h,
    children: [/* @__PURE__ */ e("div", {
      className: a("adornment", {
        visible: !!l
      }),
      children: l ? d(l, {
        dxVariant: t
      }) : null
    }), /* @__PURE__ */ r("div", {
      className: "main",
      children: [c && /* @__PURE__ */ e("h1", {
        className: "header",
        children: c
      }), o && /* @__PURE__ */ e("div", {
        className: "primary",
        children: o
      }), i && /* @__PURE__ */ e("div", {
        className: "secondary",
        children: i
      })]
    }), /* @__PURE__ */ e("div", {
      className: "action",
      children: s && /* @__PURE__ */ e("button", {
        className: b,
        type: "button",
        onClick: s,
        children: /* @__PURE__ */ e(N, {
          Icon: f,
          mqAccessibility: "semantic",
          mqTitle: "close the toast",
          mqColor: "black",
          mqSize: 16
        })
      })
    })]
  });
});
export {
  C as Alert
};
